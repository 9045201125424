export const envConfig = {
  environment: process.env.REACT_APP_ENV || 'development',
  firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  firebaseDatabaseUrl: process.env.REACT_APP_FIREBASE_DATABASE_URL || '',
  firebaseMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  googlePlaceApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY || '',
  googleProjectId: process.env.REACT_APP_GOOGLE_PROJECT_ID || '',
  mapboxToken: process.env.REACT_APP_MAPBOX_TOKEN || '',
  pusherKey: process.env.REACT_APP_PUSHER_KEY || '',
  pusherApiKey: process.env.REACT_PUSHER_API_KEY || '',
  mainAppUrl: process.env.REACT_APP_REDIRECT_URL || '',
  domainUrl: process.env.REACT_APP_DOMAIN || '',
  apiUrl: process.env.REACT_APP_REMOTE_HOST || '',
  smartlookKey: process.env.REACT_APP_SMARTLOOK || '',
  appVersion: process.env.REACT_APP_VERSION || '',
};
